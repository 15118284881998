import { RootState } from 'components_contributor/redux/rootReducer'
import { GuidedAdviceDetailed } from 'components_contributor/interfaces/commonTypes'
import { Maybe } from 'shared/util/types.util'
import { GuidedAdvice } from 'interfaces/GuidedFlow'

export const selectInvitations = (state: RootState): Array<GuidedAdviceDetailed> => {
  return state.guidedAdvice.invitations
}

export const selectInvitationsFetchPending = (state: RootState): boolean => (
  state.guidedAdvice.invitationsFetchPending
)

export const selectGuidedAdvice = (state: RootState): Maybe<GuidedAdvice> => state.guidedAdvice.guidedAdvice
